import React  from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import LogoImage from "../assets/svg/logo.svg"
import Facebook from "../assets/svg/facebook-logo.svg"

const FooterContainer = styled.footer`
  width: 100vw;
  min-width: 1500px;
  padding: 20px 240px;
  height: 250px;
  display: flex;
  justify-content: space-around;
  background-color: #D6DCE5;
  align-items: center;
  scroll-snap-align: end;
  
  @media screen and (max-width: 768px) {
    min-width: 300px;
    padding: 10px;
    height: auto;
    flex-direction: column;
  }
`;

const Logo = styled(LogoImage)`
  width: 200px;
  margin-right: 10px;
  
  @media screen and (max-width: 768px) {
    width: 100px;
    margin-right: 0;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 20px;
  display: block;
  
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const TextContainer = styled.div`
  color: black;
  font-size: 15px;
  
  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 120px;
  
  @media screen and (max-width: 768px) {
    display: flex;
    margin: auto;
    width: 150px;
    justify-content: space-around;
  }
`;

const FacebookLogo = styled(Facebook)`
  fill: white;
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;

const FacebookText = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const FacebookButton = styled.a`
  background-color: #4267b2;
  width: 150px;
  height: 55px;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  margin-bottom: 15px;
  
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const BlogLogo = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;

const BlogText = styled.div`
  font-size: 15px;
  font-family: '맑은 고딕', sans-serif;
  font-weight: normal;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const BlogButton = styled.a`
  background-color: #2DB400;
  width: 150px;
  height: 55px;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font-size: 30px;
  font-family: '맑은 고딕', sans-serif;
  font-weight: bold;
  
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;


const Footer = () => {
  return (
    <FooterContainer>
      <Logo />
      <TextContainer>
        <LinkContainer>
          <Link to="">회사 소개</Link> &nbsp;|&nbsp; <Link to="">오시는 길</Link>
        </LinkContainer>
        본사 : <address>08507 서울시 금천구 가산디지털1로 128 (가산동, 에스티엑스브이타워) 1608호</address>
        <br/>
        공장 : <address>18336 경기도 화성시 봉담읍 덕우공단 2길 85-8</address>
        <br/>
        E-mail : donga@slope.kr / TEL : 02-2107-7100
        <br/>
        Copyright(c) 2000~2020 by (주)동아특수건설. All rights reserved
      </TextContainer>
      <ButtonContainer>
        <FacebookButton href="https://www.facebook.com/slope.kr" alt="동아특수건설 페이스북">
          <FacebookLogo />
          <FacebookText>
            동아특수건설
            <br/>
            페이스북
          </FacebookText>
        </FacebookButton>
        <BlogButton href="https://blog.naver.com/PostList.naver?blogId=slopec" alt="동아특수건설 블로그">
          <BlogLogo>b</BlogLogo>
          <BlogText>
            동아특수건설
            <br/>
            블로그
          </BlogText>
        </BlogButton>
      </ButtonContainer>
    </FooterContainer>
  )
}

export default Footer
